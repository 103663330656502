<template>
  <base-modal-filters v-model="modelValue" @reset-filters="reset">
    <template #default="{ attrs, on }">
      <form-builder ref="formBuilder" :schema="schema" v-bind="attrs" v-on="on" />
    </template>
  </base-modal-filters>
</template>

<script>
// Components
import BaseModalFilters from '@/components/BaseModalFilters.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { fullSchema, schemaWithoutPaymentPeriod } from '@/schemas/invoiceFilter.schema';

// Utils
import { createModelWrapper } from '@/utils/components';

// Constants
import { UNPAID } from '@/constants/entityStatuses';

export default {
  name: 'InvoicesModalFilters',

  components: { FormBuilder, BaseModalFilters },

  inject: ['media'],

  props: {
    value: { type: Object, default: () => ({}) },
    status: { type: String, required: true },
  },

  computed: {
    modelValue: createModelWrapper('value', 'input'),

    schema() {
      if (this.isUnpaid) {
        return schemaWithoutPaymentPeriod;
      }

      return fullSchema;
    },

    isUnpaid() {
      return this.status === UNPAID;
    },
  },

  methods: {
    reset() {
      this.$refs.formBuilder?.reset();
    },
  },
};
</script>
